body {
  margin: 0;
}

body,
.mapboxgl-map {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.popup h1 {
  font-size: 1.2em;
  max-width: 80%;
}

.popup ul {
  list-style: none;
  padding-left: 0;
}

.mapboxgl-popup-content {
  font-size: 16px;
  text-align: left;
  line-height: 1.4;
  overflow-wrap: break-word;
}

.mapboxgl-popup-close-button {
  width: 26px;
  height: 26px;
  background: url("/images/close-icon.png") no-repeat top left / 26px 26px;
  color: white;
  top: 10px;
  right: 10px;
  transition: opacity 0.3s;
}

.mapboxgl-popup-close-button:hover {
  opacity: 0.6;
}

/* Disable border on search input */
.MuiAutocomplete-inputRoot > fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

@media only screen and (max-width: 600px) {
  .mapboxgl-popup {
    position: absolute;
    top: initial;
    bottom: 0;
    width: 100vw;
    height: 80vh;
    transform: none !important;
    max-width: none !important;
    will-change: initial !important;
    z-index: 10000;
  }

  .mapboxgl-popup-content {
    height: 100%;
  }

  .mapboxgl-popup-tip {
    display: none;
  }
}
