/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v17-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("/fonts/open-sans-v17-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/open-sans-v17-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/fonts/open-sans-v17-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/open-sans-v17-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/open-sans-v17-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/fonts/MaterialIcons-Regular.woff2") format("woff2"),
    url("/fonts/MaterialIcons-Regular.woff") format("woff"),
    url("/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
